.site {
  // h2.text-cr-38.text-left {
  //     display: none; // to powinien byc tytuł strony
  // }

  .bipicons {
    .icon {
      &:hover {
        .icon-text {
          text-decoration: none !important;
          color: #b61928 !important;
        }
      }
    }
  }

  .btn-outline-bip {
    color: #b51a29;
    border-color: #adb5bd;

    &:hover {
      color: #fff;
      background-color: #b51a29;
      border-color: #b51a29;
    }
  }
}
